exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-tags-js": () => import("./../../../src/pages/tags.js" /* webpackChunkName: "component---src-pages-tags-js" */),
  "component---src-pages-uses-js": () => import("./../../../src/pages/uses.js" /* webpackChunkName: "component---src-pages-uses-js" */),
  "component---src-templates-blog-post-template-js": () => import("./../../../src/templates/blogPostTemplate.js" /* webpackChunkName: "component---src-templates-blog-post-template-js" */),
  "component---src-templates-tags-template-js": () => import("./../../../src/templates/tagsTemplate.js" /* webpackChunkName: "component---src-templates-tags-template-js" */)
}

